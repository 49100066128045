<template>
    <ConfirmDialog ref="confirmDialog"/>
    <CRow style="margin-bottom: 25px">
        <CCol xs="12" style="margin-bottom: 25px; display: flex; align-items: center; justify-content: space-between">
            <h3 class="projectTemplateCollection__tableHeader">
                <CIcon name="cil-folder-new" style="margin: 0 8px 0 5px; transform: scale(1.5)"/>
                {{ label }}
            </h3>
            <BulkAction :folder-id="folderId" :default-name="defaultName" @addTemplate="addTemplate"
                        @update="$emit('update')"/>
        </CCol>
        <CCol xs="12">
            <SmartTable :items="templateCategories" :columns="dataFields" infinity :loading="loading" dropdown
                        :draggable="true" @event-drag="eventDrag" @event-drop="eventDrop">
                <template #template_name="{ item }">
                    <router-link class="d-flex text-decoration-underline w-100" target="_blank"
                                 :to="typeRouteTemplate(item)">
                        <Icon :type="item.type" class="mr-2"/>
                        <div class="ellipsis w-100">{{ item.template_name }}</div>
                        <CBadge v-if="item.is_main" style="font-size: 10px" class="m-auto" color="primary">
                            Primary
                        </CBadge>
                        <CBadge v-if="item.is_draft" style="font-size: 10px" class="m-auto" color="dark">
                            Draft
                        </CBadge>
                    </router-link>
                </template>
                <template #button="{ item }">
                    <div class="d-flex justify-around">
                        <CButton variant="ghost" @click="handleDelete(item)">
                            <CIcon name="cil-trash"/>
                        </CButton>
                    </div>
                </template>
            </SmartTable>
        </CCol>
    </CRow>
</template>

<script>
import Icon from '@/components/Template/Icon';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import BulkAction from './BulkAction';
import {permissionError, updatePlan} from '@/services/Notify/Toasts';
import Gate from '@/services/Gate/Gate';
import {generateLink} from "@/views/Templates/Components/Table/generatorLinkByTemplateType";

export default {
    components: {Icon, ConfirmDialog, BulkAction},
    inject: ['toast'],
    props: {
        data: {type: Object, default: {}},
        label: {type: String, default: ''},
        defaultName: {type: String, default: ''},
        folderId: {type: Number, default: 0},
        loading: {type: Boolean, default: false},
    },
    emits: ['delete', 'addTemplate', 'update', 'moveTemplate'],
    data() {
        return {
            key: 0,
            sort: {},
            dataFields: [
                {
                    key: 'template_name',
                    label: 'Template Name',
                    _style: '',
                    sorter: false,
                },
                {key: 'task_name', label: 'Task Name', _style: '', sorter: false},
                {key: 'type', label: 'Contract Type', _style: '', sorter: false},
                {
                    key: 'updated_at',
                    label: 'Modified',
                    _style: '',
                    sorter: false,
                    format: (value, item) =>
                        `${item.updated_at ? this.$BtoF({value: item.updated_at}) : 'N/A'} - ${
                            item.modified_by ? item.modified_by?.full_name : 'N/A'
                        }`,
                },
            ],
            templateCategories: [
                {
                    title: 'Primary Contract Package',
                    items: this.data.primary_contracts,
                    bind: {'data-flow': 'primary'},
                },
                {
                    title: 'Flowdown',
                    items: this.data.secondary_contracts,
                    bind: {'data-flow': 'secondary'},
                },
            ],
            perPage: 6,
            tempDrag: null,
        };
    },
    watch: {
        perPage() {
            this.key++;
        },
    },
    methods: {
        typeRouteTemplate(template) {
            return generateLink(template);
        },
        async handleDelete(item) {
            const check = await Gate.can('editOrCreate', 'project', this.$route.params.id);
            if (!check) {
                if (this.$store.getters.isSubPlan) this.$notify(updatePlan);
                if (this.$store.getters.isGeneralPlan) this.$notify(permissionError);
                return false;
            }

            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete this template?',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.$emit('delete', this.folderId, item.pivot_id);
                });
        },
        addTemplate(template) {
            this.$emit('addTemplate', {folderId: this.folderId, template: template});
            this.toast('success', `Task ${template.template_name} has been added to the project`);
        },
        getTr(el) {
            return el.tagName === 'TR' ? el : el.parentElement ? this.getTr(el.parentElement) : null;
        },
        eventDrag(event) {
            let el = this.getTr(event.target);
            if (el?.dataset?.flow) {
                this.tempDrag = el.dataset;
            }
        },
        eventDrop(event) {
            let el = this.getTr(event.target);
            if (el?.dataset?.flow) {
                if (this.tempDrag?.flow !== el.dataset.flow)
                    this.$emit('moveTemplate', {
                        template_id: this.tempDrag.id,
                        flow: el.dataset.flow,
                        folder_id: this.folderId,
                    });
            }
        },
    },
};
</script>

<style lang="scss"></style>
