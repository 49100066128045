<template>
    <div class="projectTemplateCollection">
        <ConfirmDialog ref="confirmDialog" />
        <CCard class="customCardTab" style="min-height: 75vh">
            <CCardBody>
                <CCol class="col-12 col-lg-12 col-xl-8">
                    <BuilderSteps :step="step" />
                </CCol>
                <div v-if="loadingPage" class="project-builder-workflow__card-loading">
                    <CSpinner color="primary" />
                </div>
                <h3 style="padding-top: 40px;">{{project.name}}</h3>
                <CCol style="border-top: 2px solid #dee4e8; padding-top: 32px">
                    <h4>Manage documents</h4>
                </CCol>
                <h5 class="additional__document-subtitle">
                    Use an existing scenario. If this project requires a new set of contracts, go to
                    <router-link v-if="canToLibrary" :to="{ name: 'Templates' }"> Templates</router-link>
                    <router-link v-else to="" @click="showError()"> Templates</router-link>
                    to create new scenarios
                </h5>
                <CRow class="mb-4 d-flex" :xs="{ cols: 2, gutter: 1 }">
                    <CCol xs="12" lg="6" class="mt-3">
                        <CRow :xs="{ cols: 2, gutter: 1 }">
                            <CCol>
                                <span class="pc-hide">Collection</span>
                            </CCol>
                            <CCol xs="12">
                                <CNSelect
                                    v-model="selectCollection"
                                    :disabled="disabled"
                                    :options="collection"
                                    label="Select Scenario"
                                    :loading="loading"
                                    required
                                    :error="errorText"
                                    :invalid="collectionError"
                                    @change="validateField('selectCollection')">
                                    <template #nooptions>
                                        <div class="project-builder__collection-no">
                                            <h4 class="project-builder__collection-no-h">Set collection</h4>

                                            <p class="project-builder__collection-no-p">
                                                There is no scenario currently available yet.
                                                <br />
                                                One needs to be set up before any project can be created.
                                                <br />
                                                Let us help you with this task!
                                            </p>

                                            <CButton
                                                color="primary"
                                                variant="outline"
                                                class="project-builder__collection-no-button"
                                                @click="$router.push({ name: 'Templates' })">
                                                Add Scenario
                                            </CButton>
                                        </div>
                                    </template>
                                </CNSelect>

                                <CFormText style="white-space: break-spaces; color: #677a89">
                                    {{ draftTemplates }}
                                </CFormText>
                            </CCol>
                        </CRow>
                    </CCol>
                    <div class="mt-3 d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex align-items-center">
                            <CIcon name="cilDescription" />
                            <a
                                class="text-muted"
                                style="margin-left: 4px; color: #0068ad !important; cursor: pointer"
                                @click="openTemplates"
                                >{{ showTemplatesText }}</a
                            >
                        </div>
                    </div>
                </CRow>
                <div v-show="showTemplates">
                    <TableCollection
                        v-for="(item, index) in listOfFolders"
                        :key="`${item.folder_id}-${index}-${key}`"
                        style="padding-top: 126px"
                        :data="item"
                        :label="item.folder_name"
                        :folder-id="item.folder_id"
                        :default-name="defaultName"
                        :loading="loading"
                        @delete="deleteItem"
                        @addTemplate="addTemplate"
                        @update="fetchData"
                        @moveTemplate="moveTemplate" />
                </div>
                <CCol md="8" class="mt-3">
                    <h4>Additional Documents</h4>
                </CCol>
                <h5 class="mb-5 additional__document-subtitle">
                    Upload all the documents provided by the owner and specific to this project.
                </h5>
                <div>
                    <label for="fileUploadBtn2" class="dragzone__input mt-4.5">
                        <input
                            id="fileUploadBtn15"
                            type="file"
                            multiple
                            class="dragzone__input-el"
                            :accept="'.xlsx,.xls,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,.dwg,.jpeg,.jpeg'"
                            @click="handleClickUpload($event)"
                            @change="uploadFile" />
                        <div class="dragzone__input-wrap">
                            <CSpinner
                                v-if="uploading"
                                style="width: 3rem; height: 3rem; color: #677a89 !important"
                                color="secondary" />
                            <CIcon v-else name="cilArrowUpload" size="3xl" style="position: relative; top: 11px" />
                            <span class="dragzone__input-title">Add contract documents</span>
                            <span class="dragzone__input-subtitle">
                                They should be the documents provided by the owner of this project.
                            </span>
                            <span class="dragzone__input-subtitle">
                                They will be added to the package when it will be sent to your third party.
                            </span>
                            <CButton class="mt-4" color="primary" :disabled="uploading">Upload Files</CButton>
                        </div>
                    </label>
                </div>
                <div style="margin-top: 40px">
                    <CRow v-for="item in additionalFiles" :key="item.id" style="padding-bottom: 28px">
                        <CCol class="d-flex" style="height: 24px; align-items: center">
                            <CIcon name="cilPrimaryDoc" />
                            <a
                                class="additional__document-title"
                                style="cursor: pointer"
                                @click="downloadDocument(item.id, item.name)">
                                {{ item.name }}
                            </a>
                            <CIcon style="cursor: pointer" name="cil-trash" @click="handleDelete(item.id)"></CIcon>
                        </CCol>
                    </CRow>
                </div>

                <FixedBottomPanel
                    :buttons="fixedBottomPanelButtons"
                    :disabled-delete-button="!$route.params.id"
                    @deleteHandler="deleteProject" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
